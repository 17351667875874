import request from '@/utils/request'

//Login
export function login(data) {
  return request({ url: 'ecl07282021', method: 'post', data: data})
} 


export function get_up(data) {
  return request({ url: 'ecget_up', method: 'post', data: data})
} 